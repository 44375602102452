/* eslint-disable */
import React, {useContext, useState} from "react"
import styled from '@emotion/styled'
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';
import { Context as EntryContext } from '../../context/EntryContext';
import Recaptcha from 'react-recaptcha';
import { intlShape, injectIntl, FormattedMessage} from 'react-intl';

import Text from '../../atoms/text'
import StyledInput from '../../components/fields/input'
import Error from '../../components/fields/error'
import Button from '../../components/fields/button'
import Section from '../../components/blocks/section'
import Column from '../../components/blocks/column'

const StyledEntryForm = styled(Form)`
    position: absolute;
    z-index: 0;
    left: 5%;
    width: 95%;
    transition: 1s;
    opacity: ${props => !props.modal ? '0':'1'};
    label {
        color: #5F5F5F;
    }
    span {
        font-size: 11pt;
        line-height: 13pt;
        color: red;
        display: block;
        margin-left: 11px;
    }
    @media(max-width: 961px){
        display: ${props => !props.modal ? 'none':'block'};
        position: relative;
        left: 0%;
        width: 94%;
        padding: 4%;
        margin-top: 150px;
        span {
            font-size: 11pt;
            line-height: 17pt;
        }
    }
`

const FlexOrder = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    span, label {
        order: 1;
        display: inline-block !important;
    }
    input {
        order: 2;
        width: 100%;
    }
`

const schema = Yup.object().shape({
    address: Yup.string()
        .required(<FormattedMessage id='contest.required' />),
    address2: Yup.string(),
    province: Yup.string(),
    city: Yup.string()
        .required(<FormattedMessage id='contest.required' />)
});

const callback = (data,setState) => {
    if(data){
      setState(true)
      console.log(data)
    }
}

const Step2 = (props) => {
    const { state, nextStep } = useContext(EntryContext)
    const [captcha, setCatpcha] = useState(false)
    const [error, setError] = useState(false)

    let initialData = {
        address: state.customer_address,
        address2:state.customer_address2,
        city:state.customer_city,
        province: state.customer_state.value
    };

    return(
      <>
          <StyledEntryForm
              schema={schema}
              onSubmit={(data) => nextStep(data,state.modalStep,state.uuid)}
              modal={state.modal}
              initialData={initialData}
              >
              <Section
                  fullWidth
                  padding="0"
                  margin="0">

                  <Column
                      width="53%"
                      padding="0% 10px 0 0"
                       mobileOrder="2"
                      >
                      <Section
                          fullWidth
                          padding="0 10% 0 0"
                          >
                          <Column
                              width="100%"
                              padding="5px 10px"
                              >
                              <FlexOrder>
                                  <StyledInput
                                      label={<FormattedMessage id='contest.address1' tagName="label" />}
                                      name="address"
                                      />
                              </FlexOrder>
                          </Column>
                          <Column
                              width="100%"
                              padding="5px 10px"
                              >
                              <FlexOrder>
                                  <StyledInput
                                      label={<FormattedMessage id='contest.address2' tagName="label" />}
                                      name="address2"
                                      />
                              </FlexOrder>
                          </Column>
                          <Column
                              width="50%"
                              padding="5px 10px"
                              >
                              <FlexOrder>
                                  <StyledInput
                                      label={<FormattedMessage id='contest.city' tagName="label" />}
                                      name="city"
                                      />
                              </FlexOrder>
                          </Column>
                          <Column
                              width="50%"
                              padding="5px 10px"
                              >
                              <StyledInput
                                  disabled
                                  label="Province"
                                  name="province"
                                  />
                          </Column>
                          <Column
                              width="100%"
                              padding="5px 10px"
                              >
                              { error ?
                                <Error>
                                  <FormattedMessage id='contest.captcha' />
                                </Error>
                                :null
                              }
                              <Recaptcha
                                style={{marginLeft:10}}
                                sitekey="6Lfi5tcUAAAAAFM-qbFgJF6ne-L226a11XsGokCU"
                                render="explicit"
                                verifyCallback={(data) => callback(data,setCatpcha)}
                              />
                          </Column>
                          <div
                              style={{
                                      width: '100%',
                                      pointerEvents: true ? 'all':'none',
                                      opacity: true ? '1':'0.5',
                                  }}>
                                  <Button
                                    disabled={!captcha}
                                    onClick={() => {
                                      if(captcha === null){
                                        setError(true)
                                      } else {
                                        setError(true)
                                      }
                                     }}
                                     type="submit">
                                     <FormattedMessage id='contest.submit' />
                                   </Button>
                          </div>

                      </Section>
                  </Column>
                  <Column
                      width="calc(47% - 20px)"
                      padding="0px 10px"
                      mobileOrder="1">
                      <Text
                          margin="10% 0 40px 0"
                          fontSize="18pt"
                          lineHeight="28pt"
                          padding="0 10% 0 0">
                         <FormattedMessage id='contest.modal2_1' />
                      </Text>
                      <Text
                          fontSize="18pt"
                          lineHeight="28pt"
                          padding="0 10% 0 0">
                          <FormattedMessage id='contest.modal2_2'>
                            {(txt) => (
                              <span dangerouslySetInnerHTML={{__html: txt}}/>
                            )}
                          </FormattedMessage>
                  </Text>
                  </Column>
              </Section>
          </StyledEntryForm>
      </>
    )
}

export default Step2
