/* eslint-disable */
import React, {Component} from 'react';
import styled from '@emotion/styled';
import {injectIntl} from 'react-intl';
import { Link } from 'gatsby'
import { Flex, Box } from '../Grid'
import Container from '../Container'

import arrowicon from '../../../assets/images/arrow-right-icon.svg'

const BlogText = styled.div`
  opacity: 0.3;
  transition: all 250ms ease-in;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  background: #000;
`

const BlogTextInner = styled.div`
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: 15px;
  background: url(${arrowicon}) no-repeat 0px 100%;
  transition: 0.3s;
  padding-bottom: 20px;
  ${tw`font-montserrat font-medium text-2xl text-white tracking-wide leading-tight`}
`

const BlogImage = styled.div`
  background: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 300px;
  position: relative;
  cursor: pointer;
  &:hover,
  &:focus {
    ${BlogText} {
      background: ${props => props.green ?'#607928':'#4774a1'};
      opacity: 0.8;
    }
  }
  :hover > div {
      animation-name: bounce;
      animation-duration: 0.7s;
      animation-timing-function: linear;
      animation-direction: forwards;
  }
`

class DidYouKnow extends Component {


  constructor(props) {
    super(props);
    this.state = {
        link: (this.props.locale == 'fr') ? 'fr/solutions-maison' : 'home-solutions'
    };
  }

  sendAnalyticEvent(articleUid){
    if(window.ga){
      window.ga(
        'send',
        'event',
        {
          eventCategory:'Home_Solutions',
          eventAction:(this.props.locale=='fr')?'Also_Like_FR':'Also_Like',
          eventLabel:this.matchEventLabels(articleUid)
        }
      )
    }
  }

  matchEventLabels(articleUid){


    //static update for tracking IDs
    if(articleUid == 'were-big-fans-of-this-energy-saving-tip' || articleUid == "seventer-proprement-tout-en-economisant"){
      return 'Energy_Saving_Fan'
    }
    if(articleUid == 'a-natural-way-to-keep-your-furry-friends-ears-clean' || articleUid == "tenir-les-oreilles-de-nos-petites-boules-de-poils-bien-propres"){
      return 'Clean_Furend_Ears'
    }
    if(articleUid == 'corn-on-the-cob-youll-be-amaized-by' || articleUid == "de-surprenants-epis-de-mais"){
      return 'Corn_cob'
    }
    if(articleUid == 'transformez-votre-salle-de-bains-en-spa-grace-a-ces-astuces' || articleUid == 'a-pet-owner-hack-perfect-for-shedding-season'){
      return 'Shedding_Season'
    }
    if(articleUid == 'say-goodbye-to-dirty-blinds'){
      return 'Dirty_Blinds'
    }
    if(articleUid == 'simple-organization-tips-to-turn-your-bathroom-into-a-spa' || articleUid ==  'transformez-votre-salle-de-bains-en-spa-grace-a-ces-astuces'){
      return 'Simple_Bathroom_Tips'
    }
    if(articleUid == 'the-grill-mate-you-should-never-be-without' || 'laide-barbecue-a-toujours-avoir-sous-la-main'){
      return 'Grill_Mate'
    }
    return articleUid
  }


  render() {
     var newSyntax = []
     if(this.props.alterSyntax){
         let tempArray = [
             this.props.blogPosts.blog_1,
             this.props.blogPosts.blog_2,
             this.props.blogPosts.blog_3,
             this.props.blogPosts.blog_4
         ]
         if(this.props.locale == 'fr'){
             tempArray[0] = this.props.blogPosts.blog_5
             tempArray[1] = this.props.blogPosts.blog_6
             tempArray[2] = this.props.blogPosts.blog_7
             tempArray[3] = this.props.blogPosts.blog_8
         }
         for (var i = 0; i < tempArray.length; i++) {
             if(this.props.currentUid != tempArray[i]._meta.uid){
                 newSyntax.push(tempArray[i])
             }
         }
     } else {
         newSyntax[0] = this.props.blogPosts.blog_1
         newSyntax[1] = this.props.blogPosts.blog_2
         newSyntax[2] = this.props.blogPosts.blog_3
     }

    return (
      <React.Fragment>
          <Container>
              <Flex
                flexWrap="wrap"
                mx={[
                  0,
                  -2,
                  -2
                ]}
              >
                <Box
                  width={[
                    1,
                    1 / 3,
                    1 / 3
                  ]}
                  px={[
                    0,
                    2,
                    2
                  ]}
                  mb={[
                    3,
                    0,
                    0
                  ]}
                >
                  <a onClick={() => { this.sendAnalyticEvent(newSyntax[0]._meta.uid) }} href={`/${this.state.link}/${newSyntax[0]._meta.uid}`}>
                    <BlogImage
                      green={this.props.green}
                      src={`${newSyntax[0].preview_image.url}`}
                      alt={`${newSyntax[0].preview_image.alt}`}
                    >
                      <BlogText>

                      </BlogText>
                      <BlogTextInner dangerouslySetInnerHTML={{__html:newSyntax[0].title[0].text}} />
                    </BlogImage>
                  </a>
                </Box>
                <Box
                  width={[
                    1,
                    1 / 3,
                    1 / 3
                  ]}
                  px={[
                    0,
                    2,
                    2
                  ]}
                  mb={[
                    3,
                    0,
                    0
                  ]}
                >
                  <a onClick={() => { this.sendAnalyticEvent(newSyntax[1]._meta.uid) }} href={`/${this.state.link}/${newSyntax[1]._meta.uid}`}>
                    <BlogImage
                      src={`${newSyntax[1].preview_image.url}`}
                      alt={`${newSyntax[1].preview_image.alt}`}
                      green={this.props.green}
                    >
                      <BlogText>

                      </BlogText>
                      <BlogTextInner dangerouslySetInnerHTML={{__html:newSyntax[1].title[0].text}} />
                    </BlogImage>
                  </a>
                </Box>
                <Box
                  width={[
                    1,
                    1 / 3,
                    1 / 3
                  ]}
                  px={[
                    0,
                    2,
                    2
                  ]}
                  mb={[
                    3,
                    0,
                    0
                  ]}
                >
                  <a onClick={() => { this.sendAnalyticEvent(newSyntax[2]._meta.uid) }} href={`/${this.state.link}/${newSyntax[2]._meta.uid}`}>
                    <BlogImage
                      src={`${newSyntax[2].preview_image.url}`}
                      alt={`${newSyntax[2].preview_image.alt}`}
                      green={this.props.green}
                    >
                      <BlogText>

                      </BlogText>
                      <BlogTextInner dangerouslySetInnerHTML={{__html:newSyntax[2].title[0].text}} />
                    </BlogImage>
                  </a>
                </Box>
              </Flex>
          </Container>
      </React.Fragment>
    )
  }
}

export default injectIntl(DidYouKnow)
