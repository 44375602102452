/* eslint-disable */
import React from "react"
import styled from '@emotion/styled'
import PropTypes from "prop-types"

const StyledButton = styled.button`
    background-image:  ${props => props.outline ? 'none':'linear-gradient(#05B935,#035D1B)'};
    padding: 12px 10px;
    width: 100%;
    text-align: center;
    border-radius: 50px;
    border: 0;
    font-family: 'Nexa-Rust-Script';
    margin: 25px 0 0 0;
    font-size: 16pt;
    cursor:pointer;
    outline: 0;
    background-color: transparent;
    border:  ${props => props.outline ? '3px solid #039365':'0'};
    color:  ${props => props.outline ? '#039365':'white'};
    opacity: ${props => props.disabled ? '0.3':'1'};
    pointer-events: ${props => props.disabled ? 'none':'all'};
    @media(max-width: 961px){
        max-width: 96%;
        margin: 25px 2% 45px;
    }
    span {
        color:  ${props => props.outline ? '#039365':'white !important'};
        font-family: 'Nexa-Rust-Script' !important;
        font-size: 16pt !important;
    }
`

const Button = ({children, action, outline, disabled}) => (
    <>
        <StyledButton
            onClick={action}
            outline={outline}
            disabled={disabled}
        >{children}
        </StyledButton>
    </>
)

Button.propTypes = {
  action:PropTypes.func,
  children:PropTypes.string
}

export default Button
