/* eslint-disable */
import styled from '@emotion/styled'

const IsDesktop =  styled.div`
  display: block;
  width: 100%;

  @media screen and (max-width:961px) {
    display: none;
  }
`

export default IsDesktop
