/* eslint-disable */
import React, { useContext } from "react"
import ThankBackground from '../images/fbanner2@2x.png'
import styled from '@emotion/styled'
import { intlShape, injectIntl, FormattedMessage} from 'react-intl';
import DidYouKnow from '../../components/common/DidYouKnow'
import { Context as EntryContext } from '../context/EntryContext';
import EventTracker from "../../components/common/EventTracker";

import Slider from "react-slick";
import "../css/slick.css";
import "../css/slick-theme.css";

import Section from './blocks/section'
import Column from './blocks/column'
import Button from './fields/button'
import Text from '../atoms/text'
import Text2 from '../atoms/text'
import Slide1 from '../images/slide1@2x.png'
import Slide2 from '../images/slide2@2x.png'
import Slide3 from '../images/slide3@2x.png'
import BCH from '../images/rhs@2x.png'
import RoyaleLogo from '../images/logo_white@2x.png'
import RoyaleLogoFr from '../images/logo_white_fr@2x.png'


const ThanksWrapper = styled.div`
    display:block;
    width: 100%;
    height: calc(100vh - 125px);
    background-image: url(${ThankBackground});
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: #9ECA3D;
    @media(max-width: 961px){
        background-size: cover;
        margin-top: 0px;
        height: auto;
    }
`

const Slide = styled.img`
    width: 75%;
    position: absolute;
    max-width: 580px;
    display: block;
    @media(max-width: 961px){
        position: relative;
        width: 100%;
        margin: 0 auto;
    }
`

const LogoWhite = styled.img`
    margin: 10% 20% 0;
    display: block;
    width: 60%;
`

const ResponsiveHeader = styled(Text2)`
    background: red !important;
`

const ResponsiveSpacer = styled.div`
    @media(max-width: 961px){
        display: block;
        width: 100%;
        height: 50px;
    }
`

const settings = {
  dots: false,
  autoplay:true,
  fade:true,
  autoplaySpeed: 3000,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
}

const StyledLink = styled.a`
    background-image: linear-gradient(#05B935,#035D1B);
    display: block;
    padding: 12px 10px;
    width: 100%;
    text-align: center;
    border-radius: 50px;
    border: 0;
    font-family: 'Nexa-Rust-Script';
    margin: 25px 0 0 0;
    font-size: 16pt;
    cursor:pointer;
    outline: 0;
    background-color: transparent;
    border: 0;
    color: white;
    text-decoration: none;
    @media(max-width: 961px){
        max-width: 96%;
        margin: 25px 2% 45px;
    }
    span {
        color: white !important;
        font-family: 'Nexa-Rust-Script' !important;
        font-size: 16pt !important;
    }
`

const ContestThankYouBanner = ({locale,blogPosts}) => {

    const { state } = useContext(EntryContext)

    return (
      <>
          <ThanksWrapper>
              <Section>
                  <Column
                      width="50%"
                      centerMode>
                      <LogoWhite src={locale == 'fr' ?  RoyaleLogoFr:RoyaleLogo} />
                  </Column>
                  <Column
                      width="50%"
                      padding="8% 0 0 0"
                      >
                      <Text
                          center
                          upperCase
                          padding="0 20% 0 15%"
                          fontSize="50pt"
                          lineHeight="60pt"
                          fontColor="white"
                          fontFamily="Gotham-Condensed-Ultra">
                         <FormattedMessage id='contest.thanks'/>
                      </Text>
                      <Text
                          center
                          fontSize="12pt"
                          lineHeight="22pt;"
                          fontColor="white"
                          fontFamily="Montserrat"
                          padding="0 30% 0 20%">
                          { state.total_contest_entries == 0 ||  state.total_contest_entries == null ?
                          <FormattedMessage id='contest.coupon'>
                            {(txt) => (
                              <span dangerouslySetInnerHTML={{__html: txt}}/>
                            )}
                          </FormattedMessage>
                          :
                          <FormattedMessage id='contest.coupon_repeat'>
                            {(txt) => (
                              <span dangerouslySetInnerHTML={{__html: txt}}/>
                            )}
                          </FormattedMessage>
                        }
                      </Text>
                      <Column
                          display="block"
                          padding="0"
                          width="40%"
                          margin="0 27.5%">
                          { state.total_contest_entries == 0 ||  state.total_contest_entries == null ?
                              <StyledLink
                                  target="_blank"
                                   href={locale == 'fr' ? 'http://www.smartsource.ca/smartsource2/static_content/app/#/index.html?Link=V7JBJRKNLNCOI&locale=fr_CA':'http://www.smartsource.ca/smartsource2/index.jsp?Link=V7JBJRKNLNCOI'}>
                                   <FormattedMessage id='contest.download_coupon'/>
                              </StyledLink>
                              :
                              null
                           }

                      </Column>
                  </Column>
              </Section>
          </ThanksWrapper>
          <Section
              fullWidth
              backgroundColor="#9ECA3D"
              >
              <Column
                  width="50%"
                  padding="3% 0 3% 10%">
                  <ResponsiveSpacer />
                  <Text
                      center
                      upperCase
                      fontSize="30pt"
                      lineHeight="40pt;"
                      fontColor="#006E32"
                      fontFamily="Gotham-Condensed">
                      <FormattedMessage id='contest.instore'/>
                  </Text>
                  <Text
                      center
                      fontSize="12pt"
                      lineHeight="22pt;"
                      fontColor="white"
                      fontFamily="Montserrat"
                      padding="0 10%">
                          <FormattedMessage id='contest.spring_ready'>
                            {(txt) => (
                              <span dangerouslySetInnerHTML={{__html: txt}}/>
                            )}
                          </FormattedMessage>
                  </Text>
                  <Column
                      display="block"
                      padding="0"
                      width="40%"
                      margin="0 29.5%">
                      <StyledLink
                          outline
                          target="_blank"
                          href={locale == 'fr' ? "../fr/offresdenettoyageduprintemps":"../springcleaningoffers"}>
                          <FormattedMessage id='contest.seeoffers'/>
                      </StyledLink>
                  </Column>
              </Column>
              <Column
                  width="50%"
                  padding="0 10% 0 0"
                  >
                  <div style={{
                          width: '100%',
                          height: '530px',
                          marginBottom: '-130px'
                      }} id="reg-slider">
                      <Slider {...settings}>
                          <Slide
                              src={Slide1}
                              />
                          <Slide
                              src={Slide2}
                              />
                          <Slide
                              src={Slide3}
                              />
                      </Slider>
                  </div>
              </Column>
          </Section>
          <Section>
              <Column
                  width="100%"
                  padding="5% 0">
                  <ResponsiveSpacer />
                  <Text
                      center
                      upperCase
                      fontSize="30pt"
                      lineHeight="40pt;"
                      fontColor="#006E32"
                      fontFamily="Gotham-Condensed">
                      <FormattedMessage id='contest.royale'>
                        {(txt) => (
                          <span dangerouslySetInnerHTML={{__html: txt}}/>
                        )}
                      </FormattedMessage>
                  </Text>
                  <Text
                      center
                      fontSize="12pt"
                      lineHeight="22pt;"
                      fontColor="#383838"
                      fontFamily="Montserrat"
                      padding="0 30%">
                      <FormattedMessage id='contest.findout'>
                        {(txt) => (
                          <span dangerouslySetInnerHTML={{__html: txt}}/>
                        )}
                      </FormattedMessage>
                  </Text>

                   <DidYouKnow
                       green
                       blogPosts={blogPosts}
                       locale={locale} />
                   <ResponsiveSpacer />
              </Column>
              { locale == 'fr' ?
                <EventTracker gtag={{'allow_custom_scripts': true,'send_to': 'DC-9904654/invmedia/royal00+standard'}} />        
                :
                <EventTracker gtag={{'allow_custom_scripts': true,'send_to': 'DC-9904654/invmedia/royal0+standard'}} />
              }
              <EventTracker gtag={{'allow_custom_scripts': true,'send_to': 'DC-8521957/invmedia/tiger0+standard'}} />
          </Section>
      </>
    )
}

export default ContestThankYouBanner
