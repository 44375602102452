/* eslint-disable */
import React, {useContext} from "react"
import styled from '@emotion/styled'
import { Form } from '@rocketseat/unform';
import PropTypes from "prop-types"
import * as Yup from 'yup';
import { Context as EntryContext } from '../context/EntryContext';
import { intlShape, injectIntl, FormattedMessage} from 'react-intl';

import StyledInput from '../components/fields/input'
import Checkbox from '../components/fields/checkbox'
import Error from '../components/fields/error'
import Button from '../components/fields/button'

const StyledForm = styled(Form)`
    width: 100%;
    padding: 0 10%;
    transition: 0.5s;
    opacity: ${props => props.modal ? '0':'1'};
    label {
        color: #5F5F5F;
    }
    span {
        font-size: 10pt;
        line-height: 14pt;
        color: red;
        display: block;
        margin-left: 5px;
    }
    @media(max-width: 961px){
        display: ${props => props.modal ? 'none':'block'};
        span {
            font-size: 11pt;
            line-height: 17pt;
        }
    }
`
const FlexOrder = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    span, label {
        order: 1;
        display: inline-block !important;
    }
    input {
        order: 2;
        width: 100%;
    }
`
const LegalTerms = styled.div`
    font-size: 11pt;
    color: #A2A2A2;
    font-family: 'Montserrat',sans-serif;
    font-weight: 300;
    a {
        color: #A2A2A2;
    }
    label {
        padding-left: 10px;
    }
`

const schema = Yup.object().shape({
  email: Yup.string()
    .email(<FormattedMessage id='contest.email_error' />)
    .required(<FormattedMessage id='contest.required' />),
  legalTerms: Yup.bool()
    .oneOf([true], <FormattedMessage id='contest.rules_error'/>)
});

const HomeForm = () => {
    const { state, startEntryProcess } = useContext(EntryContext)
    return(
      <>
          <StyledForm
              schema={schema}
              onSubmit={(data) => startEntryProcess(data)}
              modal={state.modal}
              >
              <FlexOrder>
                  <StyledInput
                      round="true"
                      label={<FormattedMessage id='contest.email' tagName="label" />}
                      name="email"
                      />
              </FlexOrder>
              <LegalTerms>
                  <Checkbox
                      name="legalTerms"
                      label={<FormattedMessage id='contest.rules'>
                                {(txt) => (
                                  <u dangerouslySetInnerHTML={{__html: txt}} />
                                )}
                              </FormattedMessage>
                            }
                      />
              </LegalTerms>
              <Button type="submit">
                  <FormattedMessage id='contest.enternow' />
              </Button>
          </StyledForm>
          { state.customer_alreadyEntered ?
              <Error>
                  <FormattedMessage id='contest.already' />
              </Error>
              :
              null
           }
      </>
    )
}

export default HomeForm
