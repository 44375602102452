/* eslint-disable */
import React from "react"
import PropTypes from "prop-types"
import styled from '@emotion/styled'
import { Input } from '@rocketseat/unform';

const StyledInput = styled(Input)`
    width: 100%;
    padding: ${props => props.round ? '8px 20px' : '8px 10px' };
    border-radius: ${props => props.round ? '25px' : '4px' };
    text-transform: ${props => props.uppercase ? 'uppercase' : 'none' };
    border: 1px solid #a3a3a3;
    font-family: 'Montserrat',sans-serif;
    font-size: 12pt;
    color: #3d3d3d;
    box-shadow: 2px 2px 5px #d0d0d0;
    margin: 10px 0;
    outline: 0;
    :disabled {
        opacity: 0.6;
    }
`

export default StyledInput
