/* eslint-disable */
import React, {useContext} from "react"
import { Context as EntryContext } from '../context/EntryContext';
import styled from '@emotion/styled'
import Step1 from './steps/step1'
import Step2 from './steps/step2'
import Text from '../atoms/text'
import { intlShape, injectIntl, FormattedMessage} from 'react-intl';

import Close from '../images/close.svg'
import Back from '../images/back.svg'

const CloseButton = styled.img`
    position:absolute;
    top: 25px;
    right: 25px;
    z-index: 0;
    width: 25px;
    cursor:pointer;
    display: ${props => props.visible ? 'block':'none'}
`

const ProgressWrapper = styled.div`
    width: 80%;
    height: 8px;
    left: 5.5%;
    border-radius: 4px;
    background-color: #e6e6e6;
    position: absolute;
    top: 10%;
    @media(max-width: 961px){
        width: 88%;
        margin-right: 0;
    }
`

const ProgressBar = styled.div`
    background-image: linear-gradient(#05B935,#035D1B);
    width: ${props => props.percentComplete};
    height: 100%;
    display: block;
    border-radius: 4px;
    transition: 0.6s;
`

const Steps = styled.div`
    display:flex;
    flex-direction: row;
    position: absolute;
    top: 5%;
    left: 5%;
    width: 50%;
    text-align:left;
    @media(max-width: 960px){
        margin-left: 20px;
    }
`

const BackButton = styled.img`
    width: 21px;
    cursor:pointer;
    @media(max-width: 960px){
        position:absolute;
        left: -25px
    }
`

const Wrapper = styled.div`
    display: ${props => props.visible ? 'block':'none'}
`

const EntryForm = (props) => {
    const { state, closeModal, goBack } = useContext(EntryContext)

    return(
        <>
          <Wrapper
              visible={state.modal}>
              <ProgressWrapper>
                  <ProgressBar
                      percentComplete={state.modalStep === 1 ?
                          '33%': state.isContestComplete ? '100%':'66%'} />
              </ProgressWrapper>
              <Steps>
                  { state.modalStep > 1 ?
                      <BackButton
                          onClick={() => goBack(state.modalStep)}
                          src={Back} />
                      :
                      ''
                   }

                  <Text
                      upperCase
                      fontFamily="Gotham-Condensed"
                      margin="0 0 0 5px"
                      fontColor="#B6B6B6">
                      { state.modalStep == 1 ?
                          <FormattedMessage id='contest.step1'/>
                          :
                          <FormattedMessage id='contest.step2'/>
                       }
                  </Text>
              </Steps>
            </Wrapper>
            { state.modalStep == 1 ?
                <Step1 />
                :
                null
            }
            { state.modalStep == 2 ?
                <Step2 />
                :
                null
            }
            <CloseButton
                visible={state.modal}
                src={Close}
                onClick={closeModal}
                />
      </>
    )
}

export default EntryForm
