/* eslint-disable */
import React from "react"
import PropTypes from "prop-types"
import {Link} from 'gatsby'
import { Provider as EntryProvider } from './context/EntryContext';
import ContestHeader from "./components/ContestHeader.js"
import { intlShape, injectIntl, FormattedMessage} from 'react-intl';

import Text from './atoms/text'
import ContestLogo from './images/gufs_logo2@2x.png'
import ContestLogoFr from './images/LogoGreenFR.png'

import dayjs from 'dayjs'

import styled from '@emotion/styled'

const ContestContainer = styled.div`
    label,li, span {
        font-family: Gotham-Condensed;
    }
    u {
        text-decoration: none;
    }
    p {
        > p {
            margin: 0 !important;
            padding: 0 !important;
        }
    }
`

const ContestFinished = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    text-align: center;
    justify-content: center;
    align-item:center;
    flex-direction: column;
`

const Logo = styled.img`
    width: 250px;
    margin: 50px calc(50% - 125px);
`

const ContestBlock = ({children,startDate,endDate,locale}) => {

    const start = dayjs(startDate)
    const end = dayjs(endDate)
    const today = dayjs()

    return (

        <EntryProvider>
            { today.isAfter(start) && today.isBefore(end) ?
                <ContestContainer>
                    {children}
                </ContestContainer>
                :
                <ContestFinished>
                    <Link to="/"><ContestHeader /></Link>
                    <Logo src={locale == 'fr' ? ContestLogoFr:ContestLogo} />
                    <Text
                        center
                        padding="0 5%">
                        <FormattedMessage id='contest.ended' />
                    </Text>
                </ContestFinished>
             }
        </EntryProvider>
    )
}

ContestBlock.propTypes = {
  children: PropTypes.node.isRequired,
}
export default ContestBlock
