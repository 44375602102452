/* eslint-disable */
import React, { useContext } from "react"
import styled from '@emotion/styled'
import { Context as EntryContext } from '../context/EntryContext';
import { Link } from 'gatsby'

import Section from './blocks/section'
import Column from './blocks/column'
import Slider from './blocks/slider'

import HomeForm from '../forms/homeForm'
import EntryForm from '../forms/entryForm'

import ContestLogo from '../images/gufs_logo2@2x.png'
import ContestLogoFr from '../images/LogoGreenFR.png'
import RightImg1 from '../../assets/images/gufs_right_section@2x.png'
import RightImgFR from '../../assets/images/gufs_right_section_fr@2x.png'

const BigLogo = styled.img`
    width: 370px;
    max-width: ${props => props.reduce ? '40%':'60%'};
    margin: ${props => props.reduce ? '0% auto 0':'20% auto 0'};
    transition: 0.5s;
    @media(max-width: 961px){
        width: ${props => props.reduce ? '40%':'60%'};
        margin: ${props => props.reduce ? '0% 30% 0':'10% 20% 0'};
    }
`

const StyledButton = styled.a`
    background-image: linear-gradient(#05B935,#035D1B);
    padding: 12px 10px;
    width: 60%;
    text-align: center;
    border-radius: 50px;
    border: 0;
    font-family: 'Nexa-Rust-Script';
    margin: 25px 20% 0;
    font-size: 16pt;
    cursor:pointer;
    outline: 0;
    background-color: transparent;
    border: 0;
    color: #fff;
    text-decoration: none;
    @media(max-width: 961px){
        max-width: 96%;
        margin: 25px 2% 45px;
    }
    span {
        color: #039365'};
        font-family: 'Nexa-Rust-Script' !important;
        font-size: 16pt !important;
    }
`

const RightImg = styled.img`
    width: 100%;
`

const ContestHomeBanner = ({locale, lockMode}) => {
    const { state } = useContext(EntryContext)
    return (
      <>
        <Section
            fullWidth
            centerMode
            padding="0"
            height="100vh"
            >
            <Column
                width="20%"
                centerMode
                >
                <Link to={locale == 'fr' ? '/fr':'/'}>
                    <BigLogo
                        reduce={state.modal}
                        src={locale == 'fr' ? ContestLogoFr:ContestLogo} />
                </Link>

                { lockMode ?
                    <StyledButton href={locale == 'fr' ? "../../fr/rafraichirsondecor":"../../refreshyourspace"}>
                        {locale == 'fr' ? 'entrez maintenant':'enter now'}
                    </StyledButton>
                    :
                    <HomeForm />
                }

            </Column>
            <Column
                centerMode
                >
                <Slider locale={locale} />
                <EntryForm />
            </Column>
        </Section>
      </>
    )
}

export default ContestHomeBanner
