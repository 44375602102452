/* eslint-disable */
import React, {useContext} from "react"
import RoyaleLogo from '../images/logo.png'
import styled from '@emotion/styled'
import Header from '../../components/theme/Header'
import { Context as EntryContext } from '../context/EntryContext';

const HeaderTop = styled.header`
    width: 100%;
    height: 95px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    @media(max-width: 961px){
        position:relative;
        height: auto;
    }
`

const Logo = styled.img`
    width: 160px;
    height: auto;
    margin: 10px 0 0 20px;
    @media(max-width: 961px){
        width: 100px;
        padding-bottom: 10px;
    }
`

const ContestHeader = () => {
    const { state } = useContext(EntryContext)
    return(
            <>
                { !state.isContestComplete ?
                    <HeaderTop>
                      <Logo src={RoyaleLogo} />
                    </HeaderTop>
                    :
                    <Header
                      urls={{
                        'en': `/refreshyourspace`,
                        'fr': `/fr/rafraichirsondecor`
                      }}
                    locale='en'
                    />
                }
            </>
    )
}

export default ContestHeader
