/* eslint-disable */
import React, {useContext, useState} from "react"
import SliderImg from '../../images/slider_temp2.png'
import styled from '@emotion/styled'
import { Context as EntryContext } from '../../context/EntryContext';
import Slider from "react-slick";
import "../../css/slick.css";
import "../../css/slick-theme.css";
import "../../css/addons.css";
import { intlShape, injectIntl, FormattedMessage} from 'react-intl';

import Text from '../../atoms/text'

import Slide1 from '../../images/vslide_1@2x.png'
import Slide2 from '../../images/vslide_2@2x.png'
import Slide3 from '../../images/vslide_3@2x.png'
import Slide4 from '../../images/vslide_4@2x.png'

import mSlide1 from '../../images/nslide1.png'
import mSlide2 from '../../images/nslide2.png'
import mSlide3 from '../../images/nslide3.png'
import mSlide4 from '../../images/nslide5.png'

import Obj1 from '../../images/obj_1@2x.png'
import Obj2 from '../../images/obj_2@2x.png'
import Obj3 from '../../images/obj_3@2x.png'
import Obj4 from '../../images/obj_4@2x.png'

import IsMobile from '../IsMobile'
import IsDesktop from '../IsDesktop'


const SliderContainer = styled.div`
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: bottom right;
    margin: 0;
    text-align: right;
    transition: 0.5s;
    transform: ${props => props.isHidden ? 'translateY(-105%)':'none'};
    overflow:hidden !important;
    @media(max-width: 961px){
        transform: none;
        display: ${props => props.isHidden ? 'none':'block'};
        opacity: ${props => props.isHidden ? '0':'1'};
        pointer-events: ${props => props.isHidden ? 'none':'all'};
    }
`

const SliderImage1 = styled.div`
    height: 100vh;
    width: 100%;
    transition: 0.5s;
    z-index: 100;
    img {
        object-fit: cover;
        object-position: left bottom;
        height: 100vh;
        width: 100%;
    }
`

const SliderImage2 = styled.div`
    position: absolute;
    top: 0;
    width: 91%;
    height: 100vh;
    z-index: 200;
    right: 0;
    transition: 0.5s;
    img {
        object-fit: cover;
        object-position: left bottom;
        height: 100vh;
        width: 100%;
    }
    transform: ${props =>
        props.currentSlide == 2 ||
        props.currentSlide == 3 ||
        props.currentSlide == 4 ?
        'none'
        :
        'translateX(65%)'};
`

const SliderImage3 = styled.div`
    position: absolute;
    top: 0;
    width: 81%;
    height: 100vh;
    z-index: 300;
    right: 0;
    transition: 0.5s;
    img {
        object-fit: cover;
        object-position: left bottom;
        height: 100vh;
        width: 100%;
    }
    transform: ${props =>
        props.currentSlide == 3 ||
        props.currentSlide == 4 ?
        'none'
        :
        'translateX(74%)'};
`

const SliderImage4 = styled.div`
    position: absolute;
    top: 0;
    width: 71%;
    height: 100vh;
    z-index: 400;
    right: 0;
    transition: 0.5s;
    img {
        object-fit: cover;
        object-position: left bottom;
        height: 100vh;
        width: 100%;
    }
    transform: ${props =>
        props.currentSlide == 4 ?
        'none'
        :
        'translateX(85%)'};
`

const RelativeWrapper = styled.div`
    postion: relative;
    height: 100vh;
`

const TextContainer = styled.div`
    height: 100vh;
    width: 70%;
    width: 575px;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    bottom: 0%;
    top: 0;
    z-index: 101;
    padding-left: ${props => props.paddingLeft};
    text-align: center;
    background-size: 100%;
    background-image: url(${props => props.backgroundImg});
    background-repeat: no-repeat;
    background-position: center;
    transition: 0.5s;
    opacity: ${props => props.thisSlide == props.currentSlide ? '1':'0'};
    transform: ${props => props.thisSlide == props.currentSlide ? 'none':'translateY(-100px)'}
`

const RotateText = styled.div`
    transform: rotate(270deg);
    position: absolute;
    bottom: ${props => props.bottom};
    left: ${props => props.left};
`

const MobileContainer  = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
`

const MobileText = (props) => {
    return (
        <MobileContainer>
            <Text
                center
                upperCase
                fontFamily="Gotham-Condensed"
                fontColor="white"
                fontSize="14pt"
                margin="5px 0 0 !important"
                padding="75px 0 0"><FormattedMessage id='contest.youcould'/></Text>
            <Text
                center
                upperCase
                fontFamily="Gotham-Condensed-Ultra"
                fontColor="white"
                fontSize="26pt"
                margin="10px 0 5px !important"
                padding="0"><FormattedMessage id='contest.slide1'>
                  {(txt) => (
                    <span dangerouslySetInnerHTML={{__html: txt}}/>
                  )}
                </FormattedMessage></Text>
            <Text
                center
                upperCase
                fontSize={props.locale == 'fr' ? "15pt":"18pt"}
                fontFamily="Nexa-Script"
                margin="5px 0 0 !important"
                fontColor="white"><FormattedMessage id='contest.slide2'>
                  {(txt) => (
                    <span dangerouslySetInnerHTML={{__html: txt}}/>
                  )}
                </FormattedMessage></Text>
            <Text
                center
                fontColor="white"
                fontSize="10pt"
                margin="9px 0 !important"
                padding="0 20% 0px"><FormattedMessage id='contest.slide3'>
                  {(txt) => (
                    <span dangerouslySetInnerHTML={{__html: txt}}/>
                  )}
                </FormattedMessage></Text>
        </MobileContainer>
    )
}

const TextComponent = (props) => {
    return (
        <TextContainer
            backgroundImg={props.backgroundImg}
            paddingLeft={props.paddingLeft}
            thisSlide={props.thisSlide}
            currentSlide={props.currentSlide}>
            <Text
                center
                upperCase
                fontFamily="Gotham-Condensed"
                fontColor="white"
                fontSize="18pt"
                margin={props.margin}
                padding="25px 0"
                padding="0"><FormattedMessage id='contest.youcould'/></Text>
            <Text
                center
                upperCase
                fontFamily="Gotham-Condensed-Ultra"
                fontColor="white"
                fontSize="44pt"
                margin="20px 0"
                padding="0">
                <FormattedMessage id='contest.slide1'>
                  {(txt) => (
                    <span dangerouslySetInnerHTML={{__html: txt}}/>
                  )}
                </FormattedMessage>
            </Text>
            <Text
                center
                upperCase
                fontSize={props.locale == 'fr' ? "24pt":"20pt"}
                fontFamily="Nexa-Script"
                margin="5px 0 20px"
                fontColor="white"><FormattedMessage id='contest.slide2'>
                  {(txt) => (
                    <span dangerouslySetInnerHTML={{__html: txt}}/>
                  )}
                </FormattedMessage></Text>
            <Text
                center
                fontColor="white"
                fontSize="11pt"
                margin="0"
                padding="0 20% 50px"><FormattedMessage id='contest.slide3'>
                  {(txt) => (
                    <span dangerouslySetInnerHTML={{__html: txt}}/>
                  )}
                </FormattedMessage></Text>
        </TextContainer>
    )
}

const SlideImage = styled.div`
    position: relative;
    z-index: 100;
    width: 100%;
    height: 330px;
    background-image: url(${props => props.backgroundImg});
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
`

const SliderComponent = ({locale}) => {
    const { state } = useContext(EntryContext)
    const [currentSlide, setCurrentSlide] = useState(1)
    var settings = {
      dots: false,
      autoplay:true,
      autoplaySpeed: 3000,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    }

    return (
        <>
            <IsDesktop>
                <SliderContainer
                    isHidden={state.modal}>
                    <SliderImage1
                        onMouseOver={() => setCurrentSlide(1)}
                        currentSlide={currentSlide}
                        isHidden={state.modal}
                        isFront={true}>
                        <RelativeWrapper>
                            <RotateText bottom="6%" left="0">
                                <Text
                                    fontColor="white"
                                    upperCase
                                    fontFamily="Gotham-Condensed-Ultra"
                                    fontSize="24pt"
                                    margin="0"
                                    padding="0"
                                    >
                                    <FormattedMessage id='contest.label1' />
                                </Text>
                            </RotateText>

                            <img src={Slide1} />
                            <TextComponent
                                backgroundImg={Obj1}
                                margin="0"
                                paddingLeft="40px"
                                thisSlide={1}
                                currentSlide={currentSlide}
                                locale={locale}
                                 />
                        </RelativeWrapper>
                    </SliderImage1>
                    <SliderImage2
                        onMouseOver={() => setCurrentSlide(2)}
                        currentSlide={currentSlide}
                        isHidden={state.modal}
                        isFront={true}
                        src={Slide2}>
                        <RelativeWrapper>
                            <RotateText bottom={locale == 'fr' ? "4.5%":"7.4%"} left={locale == 'fr' ? "16px":"-6px"}>
                                <Text
                                    fontColor="white"
                                    upperCase
                                    fontFamily="Gotham-Condensed-Ultra"
                                    fontSize="24pt"
                                    margin="0"
                                    padding="0"
                                    >
                                    <FormattedMessage id='contest.label2' />
                                </Text>
                            </RotateText>
                            <img src={Slide2} />
                                <TextComponent
                                    backgroundImg={Obj2}
                                    margin="-40px 0 0 0"
                                    paddingLeft="40px"
                                    thisSlide={2}
                                    currentSlide={currentSlide} />
                        </RelativeWrapper>
                      </SliderImage2>
                    <SliderImage3
                        onMouseOver={() => setCurrentSlide(3)}
                        currentSlide={currentSlide}
                        isHidden={state.modal}
                        isFront={true}
                        src={Slide3}>
                        <RelativeWrapper>
                            <RotateText bottom={locale == 'fr' ? "12%":"8%"} left={locale == 'fr' ? "-35px":"-8px"}>
                                <Text
                                    fontColor="white"
                                    upperCase
                                    fontFamily="Gotham-Condensed-Ultra"
                                    fontSize="24pt"
                                    margin="0"
                                    padding="0"
                                    >
                                    <FormattedMessage id='contest.label3' />
                                </Text>
                            </RotateText>
                            <img src={Slide3} />
                                <TextComponent
                                    backgroundImg={Obj3}
                                    margin="-30px 0 0 0px"
                                    paddingLeft="0px"
                                    thisSlide={3}
                                    currentSlide={currentSlide}
                                    />
                        </RelativeWrapper>
                      </SliderImage3>
                    <SliderImage4
                        onMouseOver={() => setCurrentSlide(4)}
                        currentSlide={currentSlide}
                        isHidden={state.modal}
                        isFront={true}
                        src={Slide4}>
                        <RelativeWrapper>
                            <RotateText bottom={locale == 'fr' ? "6%":"9%"} left={locale == 'fr' ? "15px":"-15px"}>
                                <Text
                                    fontColor="white"
                                    upperCase
                                    fontFamily="Gotham-Condensed-Ultra"
                                    fontSize="24pt"
                                    margin="0"
                                    padding="0"
                                    >
                                    <FormattedMessage id='contest.label4' />
                                </Text>
                            </RotateText>
                            <img src={Slide4} />
                                <TextComponent
                                    backgroundImg={Obj4}
                                    margin="-30px 0 0 0px"
                                    paddingLeft="45px"
                                    thisSlide={4}
                                    currentSlide={currentSlide}  />
                        </RelativeWrapper>
                      </SliderImage4>
                </SliderContainer>
            </IsDesktop>
            <IsMobile>
                <div style={{
                        width: '100%',
                        height:state.modal ? '0':'330px',
                        opacity: state.modal ? '0':'1',
                    }}>
                    <Slider {...settings}>
                        <SlideImage
                            backgroundImg={mSlide1}
                            >
                            <MobileText locale={locale} />
                        </SlideImage>
                        <SlideImage
                            backgroundImg={mSlide2}
                            >
                            <MobileText locale={locale} />
                        </SlideImage>
                        <SlideImage
                            backgroundImg={mSlide3}
                            >
                            <MobileText locale={locale} />
                        </SlideImage>
                        <SlideImage
                            backgroundImg={mSlide4}
                            >
                            <MobileText locale={locale} />
                        </SlideImage>
                    </Slider>
                </div>

            </IsMobile>
        </>


    )
}

export default SliderComponent
