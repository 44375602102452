/* eslint-disable */
import React, {useContext} from "react"
import styled from '@emotion/styled'
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';
import { Context as EntryContext } from '../../context/EntryContext';
import { intlShape, injectIntl, FormattedMessage} from 'react-intl';

import Text from '../../atoms/text'
import StyledInput from '../../components/fields/input'
import Checkbox from '../../components/fields/checkbox'
import Button from '../../components/fields/button'
import Section from '../../components/blocks/section'
import Column from '../../components/blocks/column'

import Icon1 from '../../images/icon1.svg'
import Icon2 from '../../images/icon2.svg'
import Icon3 from '../../images/icon3.svg'

const StyledEntryForm = styled(Form)`
    position: absolute;
    z-index: 0;
    left: 5%;
    width: 95%;
    transition: 1s;
    opacity: ${props => !props.modal ? '0':'1'};
    pointer-events: ${props => !props.modal ? 'none':'all'};
    label {
        color: #5F5F5F;
    }
    span {
        font-size: 11pt;
        line-height: 13pt;
        color: red;
        display: block;
        margin-left: 11px;
    }
    @media(max-width: 961px){
        display: ${props => !props.modal ? 'none':'block'};
        position: relative;
        left: 0%;
        width: 94%;
        padding: 4%;
        margin-top: 150px;
        span {
            font-size: 11pt;
            line-height: 17pt;
        }
    }
`

const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
const postalCodeRegExp = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;

const FlexOrder = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    span, label {
        order: 1;
        display: inline-block !important;
    }
    input {
        order: 2;
        width: 100%;
    }
`
const LegalTerms = styled.div`
    font-size: 11pt;
    color: #A2A2A2;
    font-family: 'Montserrat',sans-serif;
    font-weight: 300;
    margin-top: 10px;
    label {
        padding-left: 10px;
    }
`

const ColorList = styled.ul`
    margin: 0 0 25px;
`
const Items = styled.li`
    color: #006737;
    list-style-type: none;
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: center left;
    margin: 10px 0;
    padding: 0 0 0 35px;
    font-size: 15pt;

`
const Item1 = styled(Items)`
    background-image: url(${Icon1});
`
const Item2 = styled(Items)`
    background-image: url(${Icon2});
`
const Item3 = styled(Items)`
    background-image: url(${Icon3});
`

const ResponsiveSpacer = styled.div`
    @media(max-width: 961px){
        display: block;
        width: 100%;
        height: 50px;
    }
`



const Step1 = (props) => {
    const { state, nextStep } = useContext(EntryContext)


    const schema = Yup.object().shape({
        firstname: Yup.string()
            .required(<FormattedMessage id='contest.required' />)
            .test('len', <FormattedMessage id='contest.invalid' />, val => val.length > 2),
        lastname: Yup.string()
            .required(<FormattedMessage id='contest.required' />)
            .test('len', <FormattedMessage id='contest.invalid' />, val => val.length > 2),
        postal_code: Yup.string()
            .matches(postalCodeRegExp, 'Invalid Postal Code')
            .required(<FormattedMessage id='contest.required' />),
        year_of_birth: Yup.number(<FormattedMessage id='contest.required' />)
            .min(1900,<FormattedMessage id='contest.invalid' />)
            .max(2001,<FormattedMessage id='contest.invalid' />)
            .typeError(<FormattedMessage id='contest.required' />),
        phone: Yup.string()
            .matches(phoneRegExp, <FormattedMessage id='contest.phone_error' />)
            .required(<FormattedMessage id='contest.required' />),
        newsletter: state.uuid ? Yup.bool() : Yup.bool()
          .oneOf([true], <FormattedMessage id='contest.subscribe_error'/>)
    });

    let initialData = {
        newsletter: state.customer_newsletter,
        firstname: state.customer_firstname,
        lastname:state.customer_lastname,
        postal_code:state.customer_zipcode,
        year_of_birth: state.customer_yearofbirth,
        phone: state.customer_phone
    };

    return(
      <>
          <StyledEntryForm
              schema={schema}
              onSubmit={(data) => {nextStep(data,state.modalStep,state.uuid)}}
              modal={state.modal}
              initialData={initialData}
              >
              <Section
                  fullWidth
                  padding="0"
                  margin="0">

                  <Column
                      width="53%"
                      padding="0% 10px 0 0"
                      mobileOrder="2"
                      >
                      <Section
                          fullWidth
                          padding="0 10% 0 0"
                          >
                          <Column
                              width="50%"
                              padding="5px 10px"
                              >
                              <FlexOrder>
                                  <StyledInput
                                      label={<FormattedMessage id='contest.firstname' tagName="label" />}
                                      name="firstname"
                                      />
                              </FlexOrder>
                          </Column>
                          <Column
                              width="50%"
                              padding="5px 10px"
                              >
                              <FlexOrder>
                                  <StyledInput
                                      label={<FormattedMessage id='contest.lastname' tagName="label" />}
                                      name="lastname"
                                      />
                              </FlexOrder>
                          </Column>
                          <Column
                              width="50%"
                              padding="5px 10px"
                              >
                              <FlexOrder>
                                  <StyledInput
                                      uppercase
                                      label={<FormattedMessage id='contest.postalcode' tagName="label" />}
                                      name="postal_code"
                                      />
                              </FlexOrder>
                          </Column>
                          <Column
                              width="50%"
                              padding="5px 10px"
                              >
                              <FlexOrder>
                                  <StyledInput
                                      label={<FormattedMessage id='contest.yob' tagName="label" />}
                                      name="year_of_birth"
                                      />
                              </FlexOrder>
                          </Column>
                          <Column
                              width="100%"
                              padding="5px 10px">
                              <FlexOrder>
                                  <StyledInput
                                      mask=""
                                      label={<FormattedMessage id='contest.phone' tagName="label" />}
                                      name="phone"
                                      />
                              </FlexOrder>
                              { !state.uuid ?
                                  <LegalTerms>
                                      <Checkbox
                                          name="newsletter"
                                          label={<FormattedMessage id='contest.subscribe'>
                                            {(txt) => (
                                              <i for="newslettter" dangerouslySetInnerHTML={{__html: txt}}/>
                                            )}
                                          </FormattedMessage>}
                                          />
                                  </LegalTerms>
                                  :
                                  null

                               }
                          </Column>
                          <Button type="submit">{!state.uuid ? <FormattedMessage id='contest.subcontinue' />:<FormattedMessage id='contest.continue' />}</Button>
                      </Section>
                  </Column>
                  <Column
                      width="calc(47% - 20px)"
                      padding="0px 10px 0"
                      mobileOrder="1">
                      <ResponsiveSpacer />

                      { !state.uuid ?

                          <>
                              <Text
                                  fontFamily="Gotham-Condensed"
                                  fontColor="#006737"
                                  margin="0 0 25px 0"
                                  >
                                  <FormattedMessage id='contest.modal1_0'>
                                    {(txt) => (
                                      <u dangerouslySetInnerHTML={{__html: txt}}/>
                                    )}
                                  </FormattedMessage>
                              </Text>
                              <Text
                                  padding="0 10% 0 0">
                                <FormattedMessage id='contest.modal1_1'>
                                  {(txt) => (
                                    <u dangerouslySetInnerHTML={{__html: txt}}/>
                                  )}
                                </FormattedMessage>
                              </Text>
                              <ColorList>
                                  <Item1><FormattedMessage tagName="p" id='contest.e1' /></Item1>
                                  <Item2><FormattedMessage tagName="p" id='contest.e2' /></Item2>
                                  <Item3><FormattedMessage tagName="p" id='contest.e3' /></Item3>
                              </ColorList>

                              <Text padding="0 25% 0 0"
                                  margin="0 0 25px 0">
                                  <FormattedMessage id='contest.modal1_2'>
                                    {(txt) => (
                                      <u dangerouslySetInnerHTML={{__html: txt}}/>
                                    )}
                                  </FormattedMessage>
                              </Text>
                          </>
                        :
                            <>
                                <Text
                                    fontFamily="Gotham-Condensed"
                                    fontColor="#006737"
                                    margin="0 0 35px"
                                    >
                                    <FormattedMessage id='contest.modal1_0'>
                                      {(txt) => (
                                        <p dangerouslySetInnerHTML={{__html: txt}}/>
                                      )}
                                    </FormattedMessage>
                                </Text>
                                <Text padding="0 25% 0 0"
                                    margin="0 0 25px 0">
                                    <FormattedMessage id='contest.modal1_2_crm'>
                                      {(txt) => (
                                        <p dangerouslySetInnerHTML={{__html: txt}}/>
                                      )}
                                    </FormattedMessage>
                                </Text>
                            </>

                      }



                      <Text
                          padding="0 25% 0 0"
                          margin="0 0 25px 0">
                          <FormattedMessage tagName="span" id='contest.modal1_3' />
                      </Text>
                  </Column>
              </Section>
          </StyledEntryForm>
      </>
    )
}

export default Step1
